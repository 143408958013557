import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FullPage, Loading, Modal } from '@epcbuilder/lib/components';
import usePropertyOwnerStatuses from '@epcbuilder/lib/hooks/usePropertyOwnerStatuses';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';
import useJobEligibilityStatus from '@/hooks/jobs/useJobEligibilitystatus';
import useJobTenantEmailStatus from '@/hooks/jobs/useJobTenantEmailStatus';
import usePropertyRatings from '@/hooks/properties/usePropertyRatings';
import { EpcWizardSteps } from '@/models/job';
import EligibilityModal from './eligibility-modal/EligibilityModal';
import AccessDetailsModal from './AccessDetailsModal';
import ConfirmEmailToTenantModal from './ConfirmEmailToTenantModal';
import EligibleErrorModal from './EligibleErrorModal';
import EpcGraphCard from './EpcGraphCard';
import NextStepsCard from './NextStepsCard';
import PreTenantDetailsModal from './PreTenantDetailsModal';
import PropertyDetailsCard from './PropertyDetailsCard';
import TenantCheckModal from './TenantCheckModal';
import TenantDetailsCard from './TenantDetailsCard';

const EpcRatingStep = ({ setActive }: { setActive: Dispatch<SetStateAction<EpcWizardSteps>> }) => {
  const navigate = useNavigate();
  const { property } = usePropertyContext();
  const {
    propertyRatings,
    error: propertyRatingError,
    isLoading: propertyRatingLoading,
  } = usePropertyRatings({ id: property?.id });
  const { job } = useJobContext();
  const { jobEligibilityStatus, mutate: refetchJobEligibilityStatus } = useJobEligibilityStatus({ id: job?.id });
  const { statuses } = usePropertyOwnerStatuses();
  const { tenantEmailStatuses, mutate: refetchJobTenantEmailStatus } = useJobTenantEmailStatus({ id: job?.id });
  const hasBeenEmailed = tenantEmailStatuses && tenantEmailStatuses.length > 0;

  const [showUpdateAccessDetailsModal, setShowUpdateAccessDetailsModal] = useState<boolean>(false);
  const [showEligibilityModal, setShowEligibilityModal] = useState<boolean>(false);
  const [showEligibilityErrorModal, setShowEligibilityErrorModal] = useState<boolean>(false);
  const [showTenantCheckModal, setShowTenantCheckModal] = useState<boolean>(false);
  const [tenantCheck, setTenantCheck] = useState<boolean>(false);
  const [showPreTenantDetailsModal, setShowPreTenantDetailsModal] = useState<boolean>(false);
  const [showConfirmEmailToTenantModal, setShowConfirmEmailToTenantModal] = useState<boolean>(false);
  const [proceedToEmailTenantModal, setProceedToEmailTenantModal] = useState<boolean>(false);

  const landlordManaged = useMemo(
    () => property?.ownerStatusId === statuses?.find((status) => status.name === 'Landlord / Managing Agent')?.id,
    [property, statuses]
  );

  if (propertyRatingLoading) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  return (
    <>
      <div className="grid grid-flow-col grid-rows-6 gap-6 lg:h-[70rem] xl:h-[35rem] xl:grid-rows-3">
        <div className="order-2 col-span-3 row-span-2 xl:order-1 xl:col-span-2 xl:row-span-3">
          <EpcGraphCard propertyRatings={propertyRatings} propertyRatingError={propertyRatingError} />
        </div>
        <div className="order-1 col-span-3 row-span-1 xl:order-1 xl:col-span-2 xl:row-span-1">
          <NextStepsCard
            setActive={setActive}
            setShowTenantCheckModal={setShowTenantCheckModal}
            setShowEligibilityModal={setShowEligibilityModal}
            setShowConfirmEmailToTenantModal={setShowConfirmEmailToTenantModal}
            jobEligibilityStatus={jobEligibilityStatus}
            hasBeenEmailed={hasBeenEmailed}
            landlordManaged={landlordManaged}
          />
        </div>
        <div className="order-3 col-span-3 row-span-3 flex flex-col gap-6 sm:flex-row xl:col-span-2 xl:row-span-2">
          <PropertyDetailsCard propertyRatings={propertyRatings} />
          <TenantDetailsCard
            setShowUpdateAccessDetailsModal={setShowUpdateAccessDetailsModal}
            setProceedToEmailTenantModal={setProceedToEmailTenantModal}
            landlordManaged={landlordManaged}
          />
        </div>
      </div>
      {showTenantCheckModal && (
        <TenantCheckModal
          onClose={() => setShowTenantCheckModal(false)}
          setShowEligibilityErrorModal={setShowEligibilityErrorModal}
          setShowPreTenantDetailsModal={setShowPreTenantDetailsModal}
          setTenantCheck={setTenantCheck}
        />
      )}
      {showUpdateAccessDetailsModal && (
        <AccessDetailsModal
          eligiblityCheck={tenantCheck}
          landlordManaged={landlordManaged}
          proceedToEmailTenantModal={proceedToEmailTenantModal}
          setShowEligibilityModal={setShowEligibilityModal}
          setShowConfirmEmailToTenantModal={setShowConfirmEmailToTenantModal}
          onClose={() => {
            setShowUpdateAccessDetailsModal(false);
            setTenantCheck(false);
          }}
        />
      )}
      {showEligibilityModal && (
        <EligibilityModal
          onClose={() => setShowEligibilityModal(false)}
          refetchJobEligibilityStatus={refetchJobEligibilityStatus}
        />
      )}
      {showEligibilityErrorModal && (
        <EligibleErrorModal
          onClose={() => setShowEligibilityErrorModal(false)}
          refetchJobEligibilityStatus={refetchJobEligibilityStatus}
        />
      )}
      {showPreTenantDetailsModal && (
        <PreTenantDetailsModal
          setShowUpdateAccessDetailsModal={setShowUpdateAccessDetailsModal}
          setProceedToEmailTenantModal={setProceedToEmailTenantModal}
          onClose={() => setShowPreTenantDetailsModal(false)}
        />
      )}
      {showConfirmEmailToTenantModal && job && (
        <ConfirmEmailToTenantModal
          jobId={job.id}
          tenantEmailStatuses={tenantEmailStatuses}
          onClose={() => {
            refetchJobTenantEmailStatus();
            setShowConfirmEmailToTenantModal(false);
          }}
        />
      )}
      {property && !property.inEligibleInstallationArea && (
        <Modal id="ineligibleAreaModal" onClose={() => navigate(-1)}>
          <h1 className="pb-4">Sorry</h1>
          <p className="pb-2">
            We currently focus on providing support to customers within the Northwest, and some surrounding areas.
          </p>
          <p className="pb-4">We will be in touch as soon as we are able to support you.</p>
          <Button onClick={() => navigate(-1)}>Back</Button>
        </Modal>
      )}
    </>
  );
};

export default EpcRatingStep;
