import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { SensitiveTextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { handleFormErrors, newPasswordValidationSchema } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useUser from '@/hooks/auth/useUser';
import { ChangePassword } from '@/models/user';
import { patchPassword } from '@/network/profile';

const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required('Current Password must not be empty'),
  newPassword: newPasswordValidationSchema,
  confirmNewPassword: yup
    .string()
    .required('Confirm New Password must not be empty')
    .oneOf([yup.ref('newPassword'), ''], 'New passwords do not match'),
});

const AccountSecurity = ({ id }: { id: string }) => {
  const { user: profileData } = useUser();

  const defaultValues: ChangePassword = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<ChangePassword>({
    defaultValues,
    resolver: yupResolver(changePasswordSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ChangePassword> = async (data) => {
    try {
      await patchPassword(data);
      toast.success('Password successfully updated', { toastId: 'change-password-success' });
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<ChangePassword>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <div id={id}>
      <div className="border-neutral border-b text-start dark:border-[#516B75]">
        <h1 className="mb-2 font-semibold uppercase sm:font-bold">Account security</h1>
        <p className="sm:fonr-[18px] pb-4 font-[14px]">Change your password here.</p>
      </div>
      <form className="flex w-full flex-col gap-4 pt-6">
        <div className="border-neutral border-b pb-6 dark:border-[#516B75]">
          <p className="mb-2 font-bold">Email</p>
          <div
            id="email"
            className="text-color border-blue-lighter dark:border-primary-darker bg-color-secondary focus:border-primary placeholder:text-neutral-dark hover:shadow-grey-sm hover:border-blue-dark hover:dark:shadow-dark-sm hover:dark:border-primary disabled:text-neutral-dark disabled:dark:bg-dark-dark size-full min-h-10 resize-none break-all rounded-xl border-2 px-4 py-[6px] text-base outline-none disabled:border-[#CBD3D6] disabled:bg-[#F8F9FA] focus:dark:border-[#7AC2B9] disabled:dark:border-[#85979E]"
          >
            <span>{`Your email address is `}</span>
            <span className="block sm:inline">
              <strong>{profileData?.email}</strong>
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-6 pt-2">
          <SensitiveTextInput
            {...register('oldPassword')}
            id="oldPassword"
            name="oldPassword"
            title="Your old password"
            placeholder="Current password"
            error={errors.oldPassword?.message}
          />
          <SensitiveTextInput
            {...register('newPassword')}
            id="newPassword"
            name="newPassword"
            title="Your new password"
            placeholder="New Password"
            error={errors.newPassword?.message}
          />
          <SensitiveTextInput
            {...register('confirmNewPassword')}
            id="confirmNewPassword"
            name="confirmNewPassword"
            title="Confirm your new password"
            placeholder="Confirm New Password"
            error={errors.confirmNewPassword?.message}
          />
          <div className="w-full sm:w-48">
            <NewButton
              id="change-password-submit"
              loading={isSubmitting}
              type="submit"
              text="Change Password"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountSecurity;
