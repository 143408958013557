import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Checkbox, SensitiveTextInput, TextInput } from '@epcbuilder/lib/components';
import { AuthContext } from '@epcbuilder/lib/context/authContext';
import { Login } from '@epcbuilder/lib/models/auth';
import { Token } from '@epcbuilder/lib/models/authContext';
import { EMAIL_REGEX, handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as jose from 'jose';
import * as yup from 'yup';
import { postLogin } from '@/network/auth';

const loginSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  password: yup.string().required('Password must not be empty'),
  rememberMe: yup.bool().required(),
});

const LoginForm = () => {
  const [searchParams] = useSearchParams();
  const { dispatchLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [blockLoginMessage, setBlockLoginMessage] = useState<string | undefined>(undefined);

  const defaultValues: Login = {
    email: '',
    password: '',
    rememberMe: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    getValues,
  } = useForm<Login>({
    defaultValues,
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onSubmit',
  });

  const handleBackToLogin = () => {
    setBlockLoginMessage(undefined);
  };

  const onSubmit: SubmitHandler<Login> = async (data) => {
    try {
      const redirect = searchParams.get('redirect');
      const response = await postLogin({
        email: data.email.trim(),
        password: data.password,
        rememberMe: data.rememberMe,
      });
      if (!response) throw Error();
      const { token, refreshToken } = response.data;
      const payload = jose.decodeJwt(token) as Token;
      dispatchLogin({ token, refreshToken, rememberMe: getValues('rememberMe'), userId: payload.ID });
      toast.success('Login successful', { toastId: 'login-success' });
      navigate(redirect || '/properties');
    } catch (error: unknown) {
      const { errors, detail } = error as AxiosErrorData;
      handleFormErrors<Login>(setError, errors);

      switch (detail) {
        case 'User password has expired':
          setBlockLoginMessage(
            'Your password has expired. Please check your email for instructions on how to reset your password'
          );
          break;
        case 'Account is not confirmed':
          toast.error('Account is not confirmed, redirecting to request new verification email.', {
            toastId: 'login-unconfirmed-error',
          });
          navigate(`/resend-verification?email=${encodeURIComponent(data.email.trim())}`);
          break;
        case 'User is deleted':
          toast.error(
            'Account has been deleted. Please contact support on 0800 058 4140 if you want to reactivate the account.',
            { toastId: 'login-deleted-error' }
          );
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  return (
    <>
      {blockLoginMessage === undefined || blockLoginMessage.length <= 0 ? (
        <div>
          <div className="mt-8 flex flex-row items-center">
            <p className="text-white">
              Don't have an account?{' '}
              <Link id="registration-link" className="text-link" to="/register">
                Get started now
              </Link>
            </p>
          </div>
          <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              {...register('email')}
              id="email"
              name="email"
              title="Your email address"
              placeholder="Email"
              error={errors.email?.message}
              autoComplete="username"
            />
            <SensitiveTextInput
              {...register('password')}
              id="password"
              name="password"
              title="Your password"
              placeholder="Password"
              error={errors.password?.message}
              autoComplete="current-password"
            />
            <div className="flex flex-row flex-wrap justify-between gap-2">
              <div id="remember-me-checkbox" className="flex flex-row items-center gap-2">
                <Checkbox id="rememberMe" {...register('rememberMe')} label="Remember me" labelclassname="text-white" />
              </div>
              <Link id="forgot-password-link" className="text-link" to="forgot-password">
                Forgot password
              </Link>
            </div>
            <Button id="login-submit" loading={isSubmitting} type="submit">
              Login
            </Button>
          </form>
        </div>
      ) : (
        <>
          <div className="text-white">
            <p className="my-4">Your password has expired and needs to be reset</p>
            <p className="mb-4">
              Please check the email address associated with the account for details on how to reset your password
            </p>
            <Button onClick={handleBackToLogin} id="back-to-login">
              Back to Login
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default LoginForm;
