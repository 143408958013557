import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { GrantSchemes } from '../ecoFlexQualifyingChecker';
import { EligibilityFormData } from './EligibilityModal';
import EligibilityResults from './EligibilityResults';
import EligibilityStageContainer from './EligibilityStageContainer';
import FormCheckboxGroup from './FormCheckboxGroup';

const consentOptions = [
  {
    label:
      'I understand that by submitting this form, I fully consent to the processing of my personal data and to be contacted by email and/or phone in order to process my ECO funding.',
    value: 'consent',
  },
];

const EligibilityStageResults = ({
  eligibleFor,
  onContinue,
}: {
  eligibleFor: GrantSchemes[];
  onContinue: () => void;
}) => {
  const { setValue, watch } = useFormContext<EligibilityFormData>();

  const consent = watch('consent');

  const onItemSelect = (_: string, isChecked: boolean) => {
    setValue('consent', isChecked);
  };

  return (
    <EligibilityStageContainer title="Here are your results!">
      <p className="leading-7">Based on the information you have provided your property might be eligible for:</p>
      <EligibilityResults eligibleFor={eligibleFor} />
      <FormCheckboxGroup
        items={consentOptions}
        itemStyle="no-background"
        onItemSelect={onItemSelect}
        selectedValues={consent ? ['consent'] : []}
      />
      <NewButton id="start-questionnaire" text="Continue" onClick={onContinue} disabled={!consent} />
    </EligibilityStageContainer>
  );
};

export default EligibilityStageResults;
