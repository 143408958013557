import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Card } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import usePropertyOwnerStatuses from '@epcbuilder/lib/hooks/usePropertyOwnerStatuses';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';
import useJobSurvey from '@/hooks/jobs/useJobSurvey';
import { EligibilityStatus, EpcWizardSteps } from '@/models/job';
import { postJobSurvey } from '@/network/jobs';

const NextStepsCard = ({
  setActive,
  setShowTenantCheckModal,
  setShowEligibilityModal,
  setShowConfirmEmailToTenantModal,
  jobEligibilityStatus,
  landlordManaged,
  hasBeenEmailed,
}: {
  setActive: Dispatch<SetStateAction<EpcWizardSteps>>;
  setShowTenantCheckModal: Dispatch<SetStateAction<boolean>>;
  setShowEligibilityModal: Dispatch<SetStateAction<boolean>>;
  setShowConfirmEmailToTenantModal: Dispatch<SetStateAction<boolean>>;
  jobEligibilityStatus: EligibilityStatus | undefined;
  landlordManaged: boolean;
  hasBeenEmailed: boolean | undefined;
}) => {
  const { property } = usePropertyContext();
  const { job, mutate: refetchJob } = useJobContext();
  const { jobSurvey, error: surveyError, mutate: refetchJobSurvey } = useJobSurvey({ id: job?.id });
  const { statuses } = usePropertyOwnerStatuses();

  const isBookSurveyDisabled =
    jobSurvey && (jobSurvey.surveyStatusID === 1 || jobSurvey.surveyStatusID === 5)
      ? false // Button available when surveyStatusID is 1 or 5
      : !jobSurvey
        ? false // Button available when jobSurvey is undefined
        : job?.active;

  const handleCreateSurvey = useCallback(async () => {
    try {
      if (!job?.id) throw Error;
      if (surveyError && surveyError.detail === 'No survey could be found on the job.') {
        await postJobSurvey({ id: job?.id });
      }
      await refetchJobSurvey();
      await refetchJob();
      setActive(EpcWizardSteps.ENERGY_ASSESSMENT);
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  }, [job, surveyError, refetchJobSurvey, refetchJob, setActive]);

  const handleEligibility = useCallback(async () => {
    if (
      property?.ownerStatusId === statuses?.find((status) => status.name === 'Landlord / Managing Agent')?.id &&
      (property?.accessDetails.firstName === null ||
        property?.accessDetails.firstName === '' ||
        property?.accessDetails.lastName === null ||
        property?.accessDetails.lastName === '' ||
        property?.accessDetails.email === null ||
        property?.accessDetails.email === '' ||
        property?.accessDetails.phone === null ||
        property?.accessDetails.phone === '')
    ) {
      setShowTenantCheckModal(true);
      return;
    }

    if (landlordManaged) {
      setShowConfirmEmailToTenantModal(true);
    } else {
      setShowEligibilityModal(true);
    }
  }, [
    property,
    setShowEligibilityModal,
    setShowTenantCheckModal,
    setShowConfirmEmailToTenantModal,
    statuses,
    landlordManaged,
  ]);

  return (
    <Card>
      <div className="flex h-full flex-col justify-between sm:flex-row sm:items-center">
        <div className="flex flex-col sm:w-[69%] sm:pr-8 xl:pr-0">
          {jobEligibilityStatus?.filledInForm || hasBeenEmailed ? (
            <div className="flex h-full flex-col justify-between">
              <p className="pb-2 text-[18px] font-black uppercase">
                Next <span className="text-primary">steps</span>
              </p>
              <p>
                Proceed to Step 2 to schedule your survey. <br />
                Take a quick look at our calendar and select the most convenient time for you.
              </p>
            </div>
          ) : (
            <>
              <p className="pb-2 text-[18px] font-black uppercase">
                Are you eligible for <span className="text-primary">free home upgrades?</span>
              </p>
              <p>
                There are governmental schemes and grants available to improve your house efficiency for free. Take a
                quick check to see if you qualify!
              </p>
            </>
          )}
        </div>
        <div className="mt-2 gap-2 sm:mt-0 sm:w-[30%]">
          {jobEligibilityStatus?.filledInForm || hasBeenEmailed ? (
            <NewButton
              id="book-survey"
              onClick={() => {
                handleCreateSurvey();
                window.scrollTo({ top: 0, behavior: 'instant' });
              }}
              disabled={isBookSurveyDisabled}
              text="Book Survey"
            />
          ) : (
            <NewButton
              id="do-i-qualify"
              onClick={handleEligibility}
              disabled={!job?.active || isBookSurveyDisabled}
              text="Check Now"
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default NextStepsCard;
