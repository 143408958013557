import React, { useEffect, useState } from 'react';
import { useBelowDesktop } from '../hooks/media-queries/useMediaQueries';
import { Children } from '../utils';
import Modal from './Modal';

interface IProps {
  onClose: () => void;
  id?: string;
  children: Children;
  backgroundClasses?: string;
}

const Drawer = ({ onClose, id, children, backgroundClasses }: IProps) => {
  const isMobile = useBelowDesktop();
  const finalBackgroundClasses = backgroundClasses || 'dark:bg-dark bg-[#F8F9FA]';

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <Modal
      id={id || 'drawer'}
      onClose={onClose}
      containerClassName="!items-end"
      className={`
        ${finalBackgroundClasses} 
        transition-transform duration-300 ease-in-out 
        ${isVisible ? 'translate-y-0' : 'translate-y-full'} 
        hide-scrollbar !h-[100vh] !max-h-full w-[100vw] !max-w-full items-center !rounded-none 
        px-4 sm:px-8 md:!h-[95vh] md:!rounded-t-[20px] md:px-6
      `}
      closeButtonClassName="w-full static -top-2 -right-2 justify-end"
    >
      <div className={`size-full overflow-y-auto ${isMobile ? 'hide-scrollbar' : ''}`} id="drawer-content">
        {children}
      </div>
    </Modal>
  );
};

export default Drawer;
