import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineFilterAlt } from 'react-icons/md';
import { Checkbox, IconButton, NewAccordion, SearchBar } from '@epcbuilder/lib/components';
import { useBelowLargeDesktop } from '@epcbuilder/lib/hooks/media-queries/useMediaQueries';
import { JobStatus } from '@epcbuilder/lib/models/jobs';
import { EpcRating, epcRatingMap } from '@epcbuilder/lib/models/properties';
import usePropertyJobHistory from '@/hooks/properties/usePropertyJobHistory';
import { Filters } from '@/models/job';
import FiltersForm from './Filters';
import JobHistoryHeader from './JobHistoryHeader';
import JobHistoryTable from './JobHistoryTable';

export interface ISortConfig {
  column?: string;
  direction: string;
}

const Properties = () => {
  const defaultValues = {
    currentEpcFilters: [],
    search: '',
    showExpired: false,
    showCompleted: true,
  };

  const {
    register,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<Filters>({ defaultValues });
  const search = watch('search');
  const showExpired = watch('showExpired');
  const showCompleted = watch('showCompleted');
  const currentEpcFilters = watch('currentEpcFilters');

  const [filters, setFilters] = useState<boolean>(true);
  const [sortConfig, setSortConfig] = useState<ISortConfig>({
    column: 'statusUpdatedOn',
    direction: 'desc',
  });

  const { properties, mutate: refetchProperties, isLoading } = usePropertyJobHistory();
  const notExtraLarge = useBelowLargeDesktop();

  const refetch = useCallback(() => {
    refetchProperties();
  }, [refetchProperties]);

  const filteredProperties = useMemo(() => {
    if (!properties) return;

    if (!showCompleted && !showExpired) {
      return;
    }

    const filtered = properties.filter((property) => {
      const isJobStatusMatch =
        (showExpired && property.jobStatus !== JobStatus.COMPLETE) ||
        (showCompleted && property.jobStatus === JobStatus.COMPLETE);

      const isSearchMatch =
        !search ||
        property.addressLine1.toLowerCase().includes(search.toLowerCase()) ||
        property.city.toLowerCase().includes(search.toLowerCase()) ||
        property.postcode.toLowerCase().includes(search.toLowerCase());

      const displayedEpcRating: EpcRating | undefined = property.actualEPCRating
        ? property.actualEPCRating
        : property.currentEPCRating;

      const isEpcMatch =
        currentEpcFilters.length === 0 ||
        (displayedEpcRating && currentEpcFilters.includes(epcRatingMap[displayedEpcRating]));

      return isJobStatusMatch && isSearchMatch && isEpcMatch;
    });

    if (sortConfig.column) {
      return filtered.sort((a, b) => {
        const valueA = a[sortConfig.column as keyof typeof a];
        const valueB = b[sortConfig.column as keyof typeof b];

        const dateA = new Date(valueA as string);
        const dateB = new Date(valueB as string);

        return sortConfig.direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      });
    }

    return filtered;
  }, [properties, search, showCompleted, showExpired, currentEpcFilters, sortConfig]);

  return (
    <div className="p-6 lg:py-10">
      <div className="mb-6 flex w-full">
        <div className="inline-flex items-center">
          <p className="text-[20px] font-black uppercase lg:text-[24px]">Job History</p>
        </div>
      </div>
      <div className="flex w-full flex-col gap-4 lg:flex-row lg:justify-between">
        <div className="flex w-full flex-row justify-end lg:w-[65%]">
          <IconButton id="filters-button" style="secondary" className="min-w-12" onClick={() => setFilters(!filters)}>
            <MdOutlineFilterAlt size={20} />
          </IconButton>
          <div className="relative ml-4 w-full">
            <SearchBar<Filters> register={register} errors={errors} />
          </div>
        </div>
        <div className="flex h-full gap-4 lg:flex-col lg:gap-2 lg:self-end">
          <div id="show-expired-checkbox">
            <Checkbox id="showExpired" {...register('showExpired')} label="Show expired" />
          </div>
          <div id="show-completed-checkbox">
            <Checkbox id="showCompleted" {...register('showCompleted')} label="Show completed" />
          </div>
        </div>
      </div>
      <div className="relative w-full lg:w-[65%]">
        <NewAccordion
          accordionOpen={notExtraLarge ? !filters : filters}
          accordionChildren={<FiltersForm control={control} reset={() => reset(defaultValues)} />}
        />
      </div>
      <JobHistoryHeader sortConfig={sortConfig} setSortConfig={setSortConfig} />
      <JobHistoryTable isLoading={isLoading} properties={filteredProperties} refetch={refetch} />
    </div>
  );
};

export default Properties;
