import React, { useEffect, useMemo, useState } from 'react';
import { Modal, SimpleProgressBar } from '@epcbuilder/lib/components';
import { Address } from '@epcbuilder/lib/models/properties';
import { FormStage } from '@/models/properties';
import AddressConfirmationForm from './AddressConfirmationForm';
import PostcodeSearchForm from './PostcodeSearchForm';

const CreateNewPropertyModal = ({ onClose }: { onClose: () => void }) => {
  const [address, setAddress] = useState<Address>();
  const [bypassEPC, setBypassEPC] = useState(false);
  const [duplicateAddress, setDuplicateAddress] = useState(false);
  const [progress, setProgress] = useState(0);

  const formStage = useMemo(() => {
    return address ? FormStage.ADDRESS : FormStage.POSTCODE;
  }, [address]);

  useEffect(() => {
    if (formStage === FormStage.POSTCODE) {
      setProgress(33);
    } else if (formStage === FormStage.ADDRESS) {
      setProgress(66);
      if (bypassEPC || duplicateAddress) {
        setProgress(90);
      }
    }
  }, [formStage, address, bypassEPC, duplicateAddress]);

  return (
    <Modal
      id="create-property-modal"
      onClose={onClose}
      closeButtonClassName="mr-0 sm:-mr-8"
      className="bg-light-dark hide-scrollbar p-4 sm:h-auto sm:max-h-[80vh] sm:w-3/4 sm:min-w-[600px] sm:max-w-[728px] sm:rounded-[20px] sm:px-12 sm:pb-12 sm:pt-2"
    >
      <div className="mt-4">
        <SimpleProgressBar progress={progress} />
        {formStage === FormStage.ADDRESS && address ? (
          <AddressConfirmationForm
            address={address}
            setAddress={setAddress}
            setBypassEPC={setBypassEPC}
            setDuplicateAddress={setDuplicateAddress}
            onClose={onClose}
          />
        ) : (
          <PostcodeSearchForm setAddress={setAddress} />
        )}
      </div>
    </Modal>
  );
};

export default CreateNewPropertyModal;
