import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CgMathPlus } from 'react-icons/cg';
import { MdOutlineFilterAlt, MdUpload } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconButton, Pagination, SearchBar } from '@epcbuilder/lib/components';
import { NewAccordion } from '@epcbuilder/lib/components/Accordion';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { useFiltersContext } from '@epcbuilder/lib/context/filtersContext';
import { useBelowDesktop, useBelowLargeDesktop } from '@epcbuilder/lib/hooks/media-queries/useMediaQueries';
import BulkUploadModal from '@/components/bulk-upload';
import { CreateNewPropertyModal } from '@/components/create-property';
import useUserCompany from '@/hooks/company/useUserCompany';
import useProperties from '@/hooks/properties/useProperties';
import usePropertyCount from '@/hooks/properties/usePropertyCount';
import { Filters } from '@/models/properties';
import FiltersForm from './Filters';
import PropertiesTable from './PropertiesTable';
import PropertyHeader from './PropertyHeader';

const Properties = () => {
  const [filtersVisible, setFiltersVisible] = useState<boolean>(true);
  const [createPropertyModal, setCreatePropertyModal] = useState<boolean>(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState<boolean>(false);
  const { filters, setFilters } = useFiltersContext();
  const navigate = useNavigate();
  const { company } = useUserCompany();

  const defaultValues = {
    currentEpcFilters: [],
    potentialEpcFilters: [],
    jobStatusFilters: [],
    search: '',
    page: 1,
    limit: 10,
  };

  const {
    register,
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<Filters>({ defaultValues: filters });

  const watchedValues = watch();

  const notExtraLarge = useBelowLargeDesktop();
  const isMobile = useBelowDesktop();

  // For mobile views, we set the limit to undefined to allow infinite scrolling,
  // as we do not want to have pagination. For desktop views, use the limit
  // from the form input to display a fixed number of items per page (standard pagination).
  const propertyLimit = isMobile ? undefined : watchedValues.limit;

  const {
    properties,
    mutate: refetchProperties,
    isLoading,
    total,
  } = useProperties({
    ...watchedValues,
    limit: propertyLimit,
  });

  const { count, mutate: refetchPropertyCount } = usePropertyCount();

  const refetch = () => {
    refetchProperties();
    refetchPropertyCount();
  };

  useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(watchedValues)) {
      setFilters(watchedValues);
    }
  }, [watchedValues, filters, setFilters]);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const search = searchParams.get('search');
    if (search) setValue('search', search);
  }, [searchParams, setValue]);

  useEffect(() => {
    const modalParam = searchParams.get('modal');
    if (modalParam === 'true') {
      setCreatePropertyModal(true);
    }
  }, [searchParams]);

  const handleCloseModal = () => {
    setCreatePropertyModal(false);
    navigate('/properties');
  };

  const totalPages = Math.ceil(total / watchedValues.limit);
  const areFiltersActive =
    watchedValues.currentEpcFilters.length > 0 ||
    watchedValues.potentialEpcFilters.length > 0 ||
    watchedValues.jobStatusFilters.length > 0;

  return (
    <div className="p-6 lg:py-10">
      <div className="mb-6 flex w-full justify-between">
        <div className="inline-flex items-center">
          <p className="text-[20px] font-black uppercase lg:text-[24px]">Properties</p>
        </div>
      </div>
      <div className="flex w-full flex-row justify-between gap-4">
        <div className="flex w-full flex-row justify-end lg:w-[65%]">
          <IconButton
            id="filters-button"
            style="secondary"
            className="min-w-12"
            onClick={() => setFiltersVisible(!filtersVisible)}
          >
            <MdOutlineFilterAlt size={20} />
          </IconButton>
          <div className="relative ml-4 w-full">
            <SearchBar<Filters> register={register} errors={errors} />
          </div>
        </div>
        <div className="flex flex-row justify-end gap-4">
          <div className="w-fit">
            <NewButton
              id="add-new-button"
              variant="primary"
              text={notExtraLarge ? '' : 'Add Property'}
              icon={<CgMathPlus size={20} />}
              onClick={() => setCreatePropertyModal(true)}
            />
          </div>
          <div className="hidden w-fit md:block">
            <NewButton
              id="bulk-button"
              variant="secondary"
              text={notExtraLarge ? '' : 'Bulk Upload'}
              icon={<MdUpload size={20} />}
              onClick={() => setShowBulkUploadModal(true)}
            />
          </div>
        </div>
      </div>
      <div className="relative w-full lg:w-[65%]">
        <NewAccordion
          accordionOpen={notExtraLarge ? !filtersVisible || areFiltersActive : filtersVisible}
          accordionChildren={<FiltersForm control={control} reset={() => reset(defaultValues)} />}
        />
      </div>
      <PropertyHeader />
      <PropertiesTable
        company={company}
        count={count}
        isLoading={isLoading}
        properties={properties}
        refetch={refetch}
      />
      {!isMobile && (count ?? 0) > 0 && totalPages > 1 && (
        <div className="flex items-center justify-center pt-6">
          <Pagination
            control={control}
            name="page"
            totalPages={totalPages}
            currentPage={watchedValues.page}
            count={total}
            limit={watchedValues.limit}
          />
        </div>
      )}
      {createPropertyModal && (
        <CreateNewPropertyModal
          onClose={() => {
            refetch();
            handleCloseModal();
          }}
        />
      )}
      {showBulkUploadModal && (
        <BulkUploadModal
          onClose={() => {
            refetch();
            setShowBulkUploadModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Properties;
