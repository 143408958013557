import React from 'react';
import { Helmet } from 'react-helmet';
import { FiltersProvider } from '@epcbuilder/lib/context/filtersContext';
import Layout from '@/components/Layout';
import { Properties } from '@/components/properties';
import withAuth from '@/hoc/withAuth';

const PropertiesPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Properties">
        <FiltersProvider>
          <Properties />
        </FiltersProvider>
      </Layout>
    </>
  );
};

export default withAuth(PropertiesPage);
