import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal, NewSelect, TextAreaInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { useBelowTablet } from '@epcbuilder/lib/hooks/media-queries/useMediaQueries';
import { RefundReason } from '@epcbuilder/lib/models/payments';
import { format } from 'date-fns';
import { SurveyBooking } from '@/models/job';

interface IPaymentRefundForm {
  refundAmountSelection: number;
  refundReason: RefundReason;
  refundComments: string;
}
const numberKeyRegex = /^\d+$/;

export const BookingCancellationModal = ({
  message = '',
  messageLine2 = '',
  value,
  onClose,
  handleCancelBooking,
  handleRescheduleBooking,
}: {
  message?: string;
  messageLine2?: string;
  value?: SurveyBooking;
  onClose: () => void;
  handleCancelBooking: (reason: RefundReason, comments: string) => void;
  handleRescheduleBooking: () => void;
}) => {
  const isMobile = useBelowTablet();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<IPaymentRefundForm>({
    defaultValues: {
      refundReason: undefined,
      refundComments: '',
    },
  });

  const onSubmit = async (data: { refundReason: RefundReason; refundComments: string }) => {
    if (!data.refundReason) {
      setError('refundReason', { message: 'Refund reason must be selected' });
      return;
    }
    handleCancelBooking(data.refundReason, data.refundComments);
  };

  return (
    <Modal id="booking-cancellation-modal" onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <h1 className="font-header text-lg">Are you sure?</h1>
        <p className="mt-4">
          {message}
          <span className="font-bold">
            {value && format(new Date(value.surveyDate), 'dd/MM/yyyy')}
            {value && (value.bookingSlot === 1 ? ' AM' : ' PM')}
          </span>
          .
        </p>
        <p>{messageLine2}</p>

        <div className="flex flex-col gap-2">
          <b>Cancellation reason:</b>
          <NewSelect
            control={control}
            id="refundReason"
            name="refundReason"
            title="Select refund reason"
            placeholder="Select reason"
            className="rounded-xl"
            error={errors.refundReason?.message}
            options={Object.entries(RefundReason)
              .filter(([key]) => !numberKeyRegex.test(key))
              .map(([key, value]) => {
                return {
                  label: key
                    .toString()
                    .replace(/([A-Z])/g, ' $1')
                    .trim(),
                  value: value,
                };
              })}
          />
        </div>

        <div className="flex flex-col gap-2">
          <b>Comments:</b>
          <TextAreaInput
            id="notes"
            {...register('refundComments')}
            error={errors.refundComments?.message}
            className="rounded-xl"
          />
        </div>

        <div className="mt-6 flex flex-row justify-between gap-4">
          <NewButton
            id="reschedule-survey-button"
            onClick={() => handleRescheduleBooking()}
            variant="secondary"
            text="Reschedule"
          />
          <NewButton
            id="cancel-survey-button"
            variant="delete"
            disabled={isSubmitting}
            loading={isSubmitting}
            type="submit"
            text={isMobile ? 'Cancel' : 'Cancel Assessment'}
          />
        </div>
      </form>
    </Modal>
  );
};

export default BookingCancellationModal;
