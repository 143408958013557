import React from 'react';
import { Helmet } from 'react-helmet';
import { AuthLayout, CookieModal } from '@epcbuilder/lib/components';
import { LoginForm } from '@/components/login';

const LoginPage = () => {
  return (
    <>
      <CookieModal marketingUrl={import.meta.env.VITE_MARKETING_URL} />
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <AuthLayout header="Login">
        <LoginForm />
      </AuthLayout>
    </>
  );
};

export default LoginPage;
