import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Children } from '@epcbuilder/lib/utils';
import { Filters } from '@epcbuilder/web/src/models/properties';
import { AuthContext } from '../context/authContext';

interface FiltersContextType {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  resetFilters: () => void;
}

const defaultFilters: Filters = {
  currentEpcFilters: [],
  potentialEpcFilters: [],
  jobStatusFilters: [],
  search: '',
  page: 1,
  limit: 10,
};

const LOCAL_STORAGE_KEY_BASE = 'property-filters';

const FiltersContext = createContext<FiltersContextType | undefined>(undefined);

const generateStorageKey = (userId?: string) => `${LOCAL_STORAGE_KEY_BASE}-${userId || 'guest'}`;

export const FiltersProvider = ({ children, userId }: { children: Children; userId?: string }) => {
  const storageKey = generateStorageKey(userId);

  const [filters, setFiltersState] = useState<Filters>(() => {
    const savedFilters = localStorage.getItem(storageKey);
    if (!savedFilters) {
      return defaultFilters;
    }

    try {
      return JSON.parse(savedFilters);
    } catch (error) {
      return defaultFilters;
    }
  });

  const setFilters = useCallback(
    (newFilters: Filters) => {
      setFiltersState(newFilters);
      localStorage.setItem(storageKey, JSON.stringify(newFilters));
    },
    [storageKey]
  );

  const resetFilters = useCallback(() => {
    setFiltersState(defaultFilters);
    localStorage.removeItem(storageKey);
  }, [storageKey]);

  const { setPreLogoutAction } = useContext(AuthContext);

  useEffect(() => {
    setPreLogoutAction(resetFilters);
  }, [resetFilters, setPreLogoutAction]);

  return <FiltersContext.Provider value={{ filters, setFilters, resetFilters }}>{children}</FiltersContext.Provider>;
};

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within a FiltersProvider');
  }
  return context;
};
