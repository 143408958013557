import React from 'react';

const PropertyHeader = () => {
  return (
    <>
      <div className="border-blue-lightest dark:border-primary-darker mt-6 hidden border-b-2 lg:flex">
        <div className="mr-4 inline-block w-[1rem] sm:w-[4rem] md:mr-0"></div>
        <div className="w-full">
          <div className="flex justify-between pb-2">
            <div className="flex w-[11rem] justify-start md:w-[12rem] xl:w-[20rem]">
              <p className="text-[12px] font-bold uppercase">Property Address</p>
            </div>
            <div className="flex w-[8rem] justify-center">
              <p className="text-[12px] font-bold uppercase">Postcode</p>
            </div>
            <div className="hidden w-[8rem] justify-center xl:flex">
              <p className="text-[12px] font-bold uppercase">Potential EPC</p>
            </div>
            <div className="hidden w-[8rem] justify-center md:flex">
              <p className="text-[12px] font-bold uppercase">Current EPC</p>
            </div>
            <div className="hidden w-[8rem] justify-center md:flex">
              <p className="text-[12px] font-bold uppercase">Status</p>
            </div>
            <div className="hidden w-[8rem] justify-center lg:flex">
              <p className="text-[12px] font-bold uppercase">Last Activity</p>
            </div>
          </div>
        </div>
        <div className="mr-4 flex w-[3rem]"></div>
      </div>
    </>
  );
};

export default PropertyHeader;
