import { addMonths, endOfMonth } from 'date-fns';

const TOTAL_TERM_YEARS = 25;
const FIXED_RATE_PERIOD_YEARS = 5;

const INITIAL_RATE_APR = 7.89 / 100; // 7.89% per year
const REVERSION_RATE_APR = 7.89 / 100; // 7.89% per year, actually equal to BoE base rate + 3.14%

type FinanceOutput = {
  initialPaymentPounds: number; // during fixed term period
  reversionPaymentPounds: number; // after fixed term period
  fullTermCostPounds: number;
  totalInterestCostPounds: number;
  productFeePounds: number;
  annualPercentageRateOfCharge: number;
  initialApr: number;
  reversionApr: number;
};

type FinanceInput = {
  financeCostPounds: number;
};

/**
 * Returns the internal rate of return for a schedule of cash flows that is not necessarily periodic.
 * Used to calculate the APRC (Annual Percentage Rate of Charge) for a loan.
 *
 * https://support.microsoft.com/en-gb/office/xirr-function-de1242ec-6477-445b-b11b-a303ad9adc9d
 *
 * @param values A series of cash flows that corresponds to a schedule of payments in dates.
 * The first payment is optional and corresponds to a cost or payment that occurs at the beginning of the investment.
 * If the first value is a cost or payment, it must be a negative value. All succeeding payments are discounted based on a 365-day year.
 * The series of values must contain at least one positive and one negative value.
 *
 * @param dates A schedule of payment dates that corresponds to the cash flow payments. Dates may occur in any order.
 */
function xirr(values: number[], dates: Date[]): number {
  function xirrFunc(values: number[], dates: Date[], rate: number): number {
    return values.reduce((sum, value, index) => {
      const years = (dates[index].getTime() - dates[0].getTime()) / (1000 * 60 * 60 * 24 * 365);
      return sum + value / Math.pow(1 + rate, years);
    }, 0);
  }

  function xirrDerivative(values: number[], dates: Date[], rate: number): number {
    return values.reduce((sum, value, index) => {
      const years = (dates[index].getTime() - dates[0].getTime()) / (1000 * 60 * 60 * 24 * 365);
      return sum - (years * value) / Math.pow(1 + rate, years + 1);
    }, 0);
  }

  if (values.length !== dates.length || values.length === 0) {
    throw new Error('Invalid input: values and dates must have equal, non-zero length');
  }

  const epsilon = 1e-6;
  let guess = 0.1;

  for (let i = 0; i < 100; i++) {
    const testRate = guess - xirrFunc(values, dates, guess) / xirrDerivative(values, dates, guess);
    if (Math.abs(testRate - guess) < epsilon) {
      return testRate;
    }
    guess = testRate;
  }

  // Unable converge on IRR
  return 0;
}

/**
 * Calculates loan payments similar to Excel's PMT function
 *
 * https://support.microsoft.com/en-gb/office/pmt-function-0214da64-9a63-4996-bc20-214433fa6441
 *
 * Note that this function returns negative values. If displaying the value,
 * you'll want to convert it to a positive first.
 *
 * @param rate - Interest rate per period
 * @param nper - Total number of payment periods
 * @param pv - Present value (loan amount)
 * @returns Monthly payment amount
 */
function pmt(rate: number, nper: number, pv: number): number {
  if (rate === 0) {
    return -pv / nper;
  }

  const pvif = Math.pow(1 + rate, nper);
  return (rate / (pvif - 1)) * -(pv * pvif);
}

/**
 * Calculates Present Value (PV) similar to Excel's PV function
 *
 * https://support.microsoft.com/en-gb/office/pv-function-23879d31-0e02-4321-be01-da16e8168cbd
 *
 * @param rate - Interest rate per period
 * @param nper - Total number of payment periods
 * @param pmt - Payment amount per period
 * @returns Present value
 */
function pv(rate: number, nper: number, pmt: number): number {
  if (rate === 0) {
    return -(pmt * nper);
  }

  const pvif = Math.pow(1 + rate, nper);
  return -(pmt * ((1 - 1 / pvif) / rate));
}

/*
 * Based on formulas from the '20240723-APRC_Calculator-vImproveasy.xlsx' spreadsheet
 * attached to EPC-1232.
 */
const useFinanceCalculator = (input: FinanceInput): FinanceOutput => {
  let productFeePounds: number;

  switch (true) {
    case input.financeCostPounds >= 10000 && input.financeCostPounds <= 25000:
      productFeePounds = 595;
      break;
    case input.financeCostPounds >= 25001 && input.financeCostPounds <= 125000:
      productFeePounds = 995;
      break;
    case input.financeCostPounds >= 125001 && input.financeCostPounds <= 500000:
      productFeePounds = 1395;
      break;
    default:
      productFeePounds = 0;
      break;
  }

  const offerBalance = input.financeCostPounds + productFeePounds;
  const monthlyInitialRate = INITIAL_RATE_APR / 12;
  const monthlyReversionRate = REVERSION_RATE_APR / 12;
  const totalTermMonths = TOTAL_TERM_YEARS * 12;
  const fixedRatePeriodMonths = FIXED_RATE_PERIOD_YEARS * 12;
  const reversionRatePeriodMonths = (TOTAL_TERM_YEARS - FIXED_RATE_PERIOD_YEARS) * 12;

  const initialPaymentPounds = pmt(monthlyInitialRate, totalTermMonths, offerBalance);
  const reversionPaymentPounds = pmt(
    monthlyReversionRate,
    reversionRatePeriodMonths,
    pv(monthlyInitialRate, reversionRatePeriodMonths, initialPaymentPounds)
  );

  const fullTermCostPounds =
    -1 * (initialPaymentPounds * fixedRatePeriodMonths + reversionPaymentPounds * reversionRatePeriodMonths);

  // Generate cashflow dates and values for XIRR calculation, starting with the first (negative) payment
  const startDate = new Date(2024, 0, 31); // Start date used in Excel sheet
  const cashFlowDates: Date[] = [startDate];
  const cashFlowValues: number[] = [-input.financeCostPounds]; // Initial negative cash flow

  for (let month = 1; month <= totalTermMonths; month++) {
    // Add subsequent months, ensuring payments occur on the last day of each month
    const paymentDate = endOfMonth(addMonths(startDate, month));
    cashFlowDates.push(paymentDate);
    cashFlowValues.push(month <= fixedRatePeriodMonths ? -initialPaymentPounds : -reversionPaymentPounds);
  }

  const annualPercentageRateOfCharge = xirr(cashFlowValues, cashFlowDates) * 100;

  return {
    initialPaymentPounds: -initialPaymentPounds,
    reversionPaymentPounds: -reversionPaymentPounds,
    fullTermCostPounds: fullTermCostPounds,
    totalInterestCostPounds: fullTermCostPounds - offerBalance,
    productFeePounds,
    annualPercentageRateOfCharge: Math.round(annualPercentageRateOfCharge * 100) / 100,
    initialApr: INITIAL_RATE_APR * 100,
    reversionApr: REVERSION_RATE_APR * 100,
  };
};

export default useFinanceCalculator;
