import useMediaMaxWidth from '../useMediaMaxWidth';

export const BREAKPOINTS = {
  LARGE_DESKTOP: 1280,
  DESKTOP: 1024,
  TABLET: 768,
  MOBILE: 480,
};

/**
 * Screens smaller than 1280px (below large desktop)
 * @returns
 */
export const useBelowLargeDesktop = () => useMediaMaxWidth(BREAKPOINTS.LARGE_DESKTOP - 1);

/**
 * Screens smaller than 1024px (below desktop)
 * @returns
 */
export const useBelowDesktop = () => useMediaMaxWidth(BREAKPOINTS.DESKTOP - 1);

/**
 * Screens smaller than 768px (below tablet)
 * @returns
 */
export const useBelowTablet = () => useMediaMaxWidth(BREAKPOINTS.TABLET - 1);

/**
 * Screens smaller than 480px (below mobile)
 * @returns
 */
export const useBelowMobile = () => useMediaMaxWidth(BREAKPOINTS.MOBILE - 1);
