import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { FullPage, Loading } from '@epcbuilder/lib/components';
import { SurveyStatus } from '@epcbuilder/lib/models/jobs';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { useJobContext } from '@/context/useJobContext';
import useJobSurvey from '@/hooks/jobs/useJobSurvey';
import { JobContact } from '@/models/job';
import { patchRequestCallback } from '@/network/jobs';
import { CallUsCard, RequestCallbackCard } from '../ContactCards';
import BookingPickerCard from './BookingPickerCard';
import FindOutMoreCard from './FindOutMoreCard';
import NextStepsCard from './NextStepsCard';
import SurveyDetailsCard from './SurveyDetailsCard';

const EnergyAssessmentStep = () => {
  const { job } = useJobContext();
  const { jobSurvey, mutate: refetchJobSurvey } = useJobSurvey({ id: job?.id });

  const handleRequestCallback = useCallback(async () => {
    try {
      await patchRequestCallback({ id: job?.id, type: JobContact.SURVEY });
      toast.success(
        'Callback has been requested, someone from the admin team will be in contact to confirm your booking as soon as possible.'
      );
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  }, [job]);

  if (!jobSurvey) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  const surveyCompleted = [
    SurveyStatus.SURVEY_COMPLETED,
    SurveyStatus.REPORT_IN_PROGRESS,
    SurveyStatus.REPORT_COMPLETED,
  ].includes(jobSurvey?.surveyStatusID);

  return (
    <div className="flex items-center justify-center">
      <div className={`grid ${!surveyCompleted ? 'gap-6 lg:grid-cols-3 xl:grid-cols-12' : ''} justify-center`}>
        <div
          className={`${!surveyCompleted ? 'w-full lg:col-span-3 xl:col-span-8 xl:row-span-3' : 'min-w-none w-full max-w-4xl lg:min-w-[40rem]'}`}
        >
          {jobSurvey.surveyStatusID === 1 || jobSurvey.surveyStatusID === 2 || jobSurvey.surveyStatusID === 5 ? (
            <BookingPickerCard jobSurvey={jobSurvey} refetchJobSurvey={refetchJobSurvey} />
          ) : (
            <SurveyDetailsCard jobSurvey={jobSurvey} refetchJobSurvey={refetchJobSurvey} />
          )}
        </div>
        {!surveyCompleted && (
          <>
            <div className="flex flex-row gap-6 lg:order-1 xl:col-span-4 xl:row-span-1">
              <a href="tel:08000584140" className="flex-1">
                <CallUsCard />
              </a>
              <div className="flex-1">
                <RequestCallbackCard onClick={() => handleRequestCallback()} />
              </div>
            </div>
            <div className="flex flex-col lg:order-2 xl:col-span-4 xl:row-span-1">
              <FindOutMoreCard />
            </div>
            <div className="flex flex-col lg:order-3 xl:col-span-4 xl:row-span-1">
              <NextStepsCard jobSurvey={jobSurvey} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EnergyAssessmentStep;
