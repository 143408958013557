import React from 'react';
import { Modal } from '@epcbuilder/lib/components';

const WhatToExpectModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal id="what-to-expect-modal" onClose={onClose}>
      <div className="p-4">
        <h1 className="font-header text-2xl" id="what-to-expect-heading">
          Energy Assessment - What To Expect
        </h1>
        <p className="my-4">
          The assessment will typically last 1 – 2 hours depending on the size of your property and will include:
        </p>

        <div>
          <ul className="mb-6 list-disc space-y-2">
            <li>
              <b>Building Fabric:</b> The assessor examines the construction materials and the condition of walls,
              floors, roofs, windows, and doors.
            </li>
            <li>
              <b>Heating System:</b> Information on the type, age, and efficiency of boilers, radiators, underfloor
              heating, and thermostats is collected.
            </li>
            <li>
              <b>Insulation:</b> The assessor checks for insulation in walls, roofs, and floors. They will require
              access to the loft to assess if there is any insulation present and if so, what depth it is.
            </li>
            <li>
              <b>Hot Water System:</b> The type, efficiency, and controls of the hot water system are assessed.
            </li>
            <li>
              <b>Lighting:</b> The type and efficiency of lighting installed in the property are evaluated.
            </li>
          </ul>

          <div className="bg-brand-gradient dark:bg-dark-brand-gradient mb-6 h-[0.2rem] w-full"></div>

          <p className="mb-4 text-center font-bold">During the process the Assessor will:</p>
          <ul className="list-disc space-y-2">
            <li>
              Take various measurements, such as room sizes, wall thickness, and window dimensions, this is to calculate
              heat loss.
            </li>
            <li>
              Record all details related to the building's energy use and efficiency. This includes the age and the the
              property, heating and cooling systems, insulation levels, and energy-saving features.
            </li>
            <li>Take photographs as part of the documentation process.</li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default WhatToExpectModal;
