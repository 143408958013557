import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { Card } from '@epcbuilder/lib/components';
import { useBelowDesktop } from '@epcbuilder/lib/hooks/media-queries/useMediaQueries';
import { Job } from '@epcbuilder/lib/models/jobs';

const JobCompleteConfetti = () => {
  const isMobile = useBelowDesktop();
  const confettiCount = isMobile ? 50 : 100;
  const [shouldMakeConfettiBits, setShouldMakeConfettiBits] = useState(true);

  useEffect(() => {
    setTimeout(() => setShouldMakeConfettiBits(false), 5000);
  }, []);

  return (
    <Confetti
      opacity={0.9}
      numberOfPieces={shouldMakeConfettiBits ? confettiCount : 0}
      width={isMobile ? 320 : 659}
      height={isMobile ? 580 : 540}
      initialVelocityY={-5}
    />
  );
};

const JobComplete = ({ job }: { job?: Job }) => {
  return (
    <div className="grid justify-center">
      <Card className="p-8 lg:max-w-[745px]">
        <JobCompleteConfetti />
        <div className="flex flex-col items-center gap-8">
          <div className="flex w-full flex-col items-center gap-4 sm:flex-row">
            <div className="relative size-[140px] lg:size-[195px]">
              <img
                id="congratulations-png"
                src="/images/congratulations.png"
                alt="Congratulations"
                className="object-contain"
              />
            </div>
            <div className="flex max-w-96 flex-col text-left">
              <p id="congratulations-heading" className="text-lg font-bold lg:text-xl">
                Congratulations on improving the energy efficiency of your property.
              </p>
            </div>
          </div>
          <div id="text-container" className="flex flex-col gap-2 text-left lg:px-4">
            <p className="font-sm">
              Thank you for trusting us with your home energy upgrade funding and installs. We hope your home is now
              warm and cozy, powered by green energy at no extra cost to you.
            </p>
            <p className="font-sm">
              As always, if any issues arise, please don’t hesitate to contact us and one of our friendly team members
              will be happy to assist you.
            </p>
          </div>
          <div className="bg-blue flex w-full flex-col items-center justify-around gap-4 rounded-[16px] p-4 sm:flex-row dark:bg-[#195E55]">
            <div className="flex flex-row items-center gap-2">
              <p className="font-bold">Your EPC rating was:</p>
              <p id="previous-epc" className="text-error text-[24px] font-extrabold dark:text-[#F1A997]">
                {job?.actualEpc}
              </p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <p id="new-epc" className="font-bold">
                Your EPC rating is now:
              </p>
              <p className="text-primary dark:text-blue-dark text-[24px] font-extrabold">{job?.newEpc}</p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default JobComplete;
