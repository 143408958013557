import React, { useEffect, useState } from 'react';
import { MdBookmarkAdded, MdOutlineNotes } from 'react-icons/md';
import { Loading, Modal } from '@epcbuilder/lib/components';
import AddToCalendarModalButton from '@epcbuilder/lib/components/add-to-calendar/AddToCalendarButton';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { useBelowTablet } from '@epcbuilder/lib/hooks/media-queries/useMediaQueries';
import { InstallationStatus, JobInstallation } from '@epcbuilder/lib/models/jobs';
import { format } from 'date-fns';
import { usePropertyContext } from '@/context/usePropertyContext';
import useUser from '@/hooks/auth/useUser';
import useInstallationBooking from '@/hooks/installation/useInstallationBooking';

function getCardTitles(status: InstallationStatus): { title: string; subtitle: string } {
  switch (status) {
    case InstallationStatus.INSTALLATION_IN_PROGRESS:
      return {
        title: 'Your installation is in progress',
        subtitle:
          'Our team are currently working on installing your measures. Please contact our team on 0800 058 4140 if you have any questions',
      };
    case InstallationStatus.INSTALLATION_COMPLETED:
      return {
        title: 'Your installation has been completed',
        subtitle: 'Your home upgrades have been installed!',
      };
    case InstallationStatus.INSTALLATION_CANCELLED:
      return {
        title: 'Your installation has been cancelled',
        subtitle: 'Please contact our team on 0800 058 4140 if you need anything.',
      };
    default:
      return {
        title: 'Your installation is booked',
        subtitle: 'Installation confirmation email has been sent to you.',
      };
  }
}

const getDateWithHours = (date: string, hours: number): string => {
  return `${date?.substring(0, date.indexOf('T') + 1)}${hours.toString().padStart(2, '0')}:00:00`;
};

const InstallationDetailsCard = ({ jobInstallation }: { jobInstallation: JobInstallation }) => {
  const [loading, setLoading] = useState(true);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const isMobile = useBelowTablet();
  const { installationBooking, mutate: refetchInstallationBooking } = useInstallationBooking({
    id: jobInstallation?.id,
  });
  const { user } = useUser();
  const { property } = usePropertyContext();
  const isInstallationCompleted = jobInstallation?.installationStatusId === InstallationStatus.INSTALLATION_COMPLETED;
  const cardText = getCardTitles(jobInstallation.installationStatusId);

  useEffect(() => {
    if (jobInstallation?.id) {
      refetchInstallationBooking().finally(() => setLoading(false));
    }
  }, [jobInstallation, refetchInstallationBooking]);

  return (
    <>
      <div className="bg-light-dark dark:bg-dark-dark shadow-grey-sm dark:shadow-dark-sm relative flex size-full cursor-default flex-col gap-4 rounded-[22px] p-4 lg:px-14 lg:py-6">
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className="flex w-full flex-col gap-2">
              <div className="flex flex-row items-center">
                <MdBookmarkAdded size={56} className="text-color-brand" />
                <div>
                  <p className="pl-4 text-[20px] font-bold">{cardText.title}</p>
                  <p className="pl-4">{cardText.subtitle}</p>
                </div>
              </div>
              <div className="bg-brand-gradient dark:bg-dark-brand-gradient mb-4 h-[0.2rem] w-full"></div>
            </div>
            <div className="flex flex-col gap-4 pl-4">
              <div className="flex flex-row items-start">
                <p className="w-[30%]">Installer:</p>
                <p id="installer-name" className="truncate font-bold">
                  {installationBooking?.assessor}
                </p>
              </div>
              <div className="flex flex-row items-start">
                <p className="w-[30%]">When:</p>
                <p id="installation-date" className="font-bold">
                  {installationBooking?.bookingDateTime
                    ? format(new Date(installationBooking?.bookingDateTime), 'EEEE, MMMM dd, yyyy')
                    : '-'}
                  <br />
                  {installationBooking?.bookingDateTime
                    ? format(new Date(installationBooking.bookingDateTime), 'hh:mm a')
                    : '-'}
                </p>
              </div>

              <div className="flex flex-row items-start">
                <p className="w-[30%]">What:</p>
                <div id="installation-improvement-items" className="flex-1 font-bold md:max-w-full">
                  {installationBooking?.selectedImprovement?.items?.length ? (
                    <div className="flex flex-col">
                      {installationBooking.selectedImprovement.items
                        .filter((improvementItem) => !improvementItem?.improvementItemType?.isDiscount)
                        .map((improvementItem) => (
                          <div key={`improvement-item-${improvementItem.id}`} className="flex flex-row justify-between">
                            <p id={`improvement-item-${improvementItem.itemText}`} className="flex-1">
                              {improvementItem.itemText}
                            </p>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <p>No improvements selected.</p>
                  )}
                </div>
              </div>

              <div className="flex flex-row items-start">
                <p className="w-[30%]">Where:</p>
                <p id="installation-location" className="max-w-56 font-bold md:max-w-full">
                  {`${property?.addressLine1}${property?.addressLine2 ? `, ${property.addressLine2}` : ''}, ${property?.postcode}`}
                </p>
              </div>
              <div className="flex flex-row items-start">
                <p className="w-[30%]">Who:</p>
                <div>
                  <p id="user-name" className="font-bold">
                    {user?.firstName} {user?.lastName}
                  </p>
                  {isMobile ? '' : <p id="user-email">{user?.email}</p>}
                </div>
              </div>
            </div>

            <div className="relative mt-6 flex w-full flex-col gap-4">
              {!isInstallationCompleted && (
                <div className="flex w-full flex-col gap-4 md:flex-row">
                  <div className="w-full self-center">
                    <NewButton
                      id="notes-button"
                      variant="secondary"
                      text="Notes"
                      disabled={!installationBooking?.notes}
                      icon={<MdOutlineNotes size={20} />}
                      onClick={() => {
                        setShowNotesModal(true);
                      }}
                    />
                  </div>
                  <AddToCalendarModalButton
                    name={`Improvements Installation - ${property?.addressLine1}`}
                    location={
                      property?.addressLine1 && property?.postcode
                        ? `${property.addressLine1}, ${property.postcode}`
                        : undefined
                    }
                    startDate={installationBooking?.bookingDateTime.toString() || ''}
                    endDate={
                      installationBooking?.bookingDateTime
                        ? getDateWithHours(installationBooking?.bookingDateTime.toString(), 17)
                        : ''
                    }
                    containerClassName="min-w-fit w-full"
                  />
                </div>
              )}
              {!isInstallationCompleted && (
                <p className="relative bottom-0">
                  If you want to reschedule or cancel your installation, please give us a call.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {showNotesModal && (
        <Modal onClose={() => setShowNotesModal(false)}>
          <div className="flex flex-col">
            <h1 className="font-header mb-4 text-lg">Booking Notes</h1>
            <p id="installation-notes">{installationBooking?.notes}</p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default InstallationDetailsCard;
